/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa"; // Import the cross icon
import { TbFaceIdError } from "react-icons/tb";
import { usePlaidLink } from "react-plaid-link";
import ProgressBar from "./ProgressBar";
import Loader from "../common/Loader";

function EarnestMoneyComponent() {
  const { user } = useAuth0();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [linkToken, setLinkToken] = useState(null);
  const [accountLinkStatus, setAccountLinkStatus] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [propertyData, setPropertyData] = useState({});
  const [builderInfo, setBuilderInfo] = useState({});
  const [builderConfig, setBuilderConfig] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [plaidStatus, setPlaidStatus] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    open();
  };
  const getMerchantId = async () => {
    let builderInfo = localStorage.getItem("builderInfo");
    builderInfo = JSON.parse(builderInfo);
    setMerchantId(builderInfo?.merchantid);
  };


  const plaidOpen = () => {
    setPlaidStatus(true);
    open();
  }

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      const response = await axios.post(`${apiBaseUrl}/exchange_public_token`, {
        public_token,
      });
      const { access_token, item_id } = response.data;
      balanceCheck(access_token, metadata, item_id, public_token);
    },
  });

  const balanceCheck = async (token, metadata, item_id, public_token) => {
    try {
      const balanceResponse = await axios.get(
        `${apiBaseUrl}/balance/get/${token}`
      );
      let balance;
      if (balanceResponse.data.length >= 0) {
        balance = balanceResponse.data[0].balances.available;
        if (balance <= 100) {
          toggleModal();
        } else {
          setAccessToken(token);
          setAccountInfo(metadata.accounts[0]);
          setAccountLinkStatus(true);
        }
      }
    } catch (error) {
      toggleModal();
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   let propertyInfo = localStorage.getItem("propertyInfo");
  //   propertyInfo = JSON.parse(propertyInfo);
  //   let userData = localStorage.getItem("userInfo");
  //   userData = JSON.parse(userData);
  //   setUserInfo(userData);
  //   setPropertyData(propertyInfo);
  //   getMerchantId(propertyInfo);
  // }, [localStorage.getItem("propertyInfo"), localStorage.getItem("userInfo")]);
  const getBuilderInfo = async () => {
    let builderValue = localStorage.getItem("builderInfo");
    builderValue = JSON.parse(builderValue);
    setBuilderInfo(builderValue);
  }



  const getBuilderConfig = async () => {
    let builderConfigValue = localStorage.getItem("builderConfig");
    builderConfigValue = JSON.parse(builderConfigValue);
    setBuilderConfig(builderConfigValue);
  }
  const getPropertyData = async () => {
    let propertyValue = localStorage.getItem("propertyInfo");
    propertyValue = JSON.parse(propertyValue);
    setPropertyData(propertyValue);
  }

  const getUserData = async () => {
    let userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    setUserInfo(userData);
  }

useEffect(() => {
  const processData = async () => {
    await getBuilderInfo();
          await getBuilderConfig();
          await getPropertyData();
          await getUserData();
          await getMerchantId();
          setIsLoading(false);
  };

  processData();
}, []);

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("userInfo"));
    const createLinkToken = async () => {
      const response = await axios.post(`${apiBaseUrl}/create_link_token`, {
        userId: users?.userId,
      });
      setLinkToken(response.data.link_token);
    };
    if (users && users?.userId.length > 0) {
      console.log("user email", users);
      createLinkToken();
    }
  }, [apiBaseUrl]);

  const submitEarnest = async () => {
    setLoading(true);
    const earnestAmount = propertyData?.earnest_price;
    console.log("data info", userInfo);
    try {
      const response = await axios.post(`${apiBaseUrl}/payment/transfer`, {
        accessToken: accessToken,
        bankAccount: accountInfo,
        merchantId: merchantId,
        isIdentity: true,
        isProcessor: true,
        amount: earnestAmount,
        userInfo: userInfo,
      });
      console.log("response", response);
      if (response.status === 200) {
        setLoading(false);
        navigate("/complete");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toggleModal();
    }
  };

  const handlePrevious = () => {
    // if (step > 1) {
      // setStep(step - 1);
      // setCurrentStep(currentStep - 1);
    // } else {
      // window.location.replace("/");
      navigate(-1); 
    // }
  };

  return isLoading? ( <Loader /> ) :   (
    <div className="min-h-screen flex flex-col items-center">
      {/* <div className="flex items-center justify-center mb-[60px] mt-10">
        <img
          src="./assets/images/main_logo.png"
          alt="homesylogo"
          className="w-full h-auto"
        />
      </div> */}
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[175px] items-center mt-10">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">
              
            </div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <img
              src={builderConfig?.logo}
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">Powered by Homesy.</p>
          </div>
          {/* <div className="flex items-center justify-center mt-10"> */}
          {/* <img src='./assets/images/main_logo.png'  alt='homesylogo' className="w-full h-auto"/> */}
        </div>
      </div>
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[175px] items-center">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">
            <button
                type="button"
                onClick={handlePrevious}
                className="py-[20px] bg-white text-[17px] text-gray-700 font-semibold rounded-full flex items-center justify-center"
              >
                <img
                  src="./assets/images/back-arrow-icon.svg"
                  alt="back-icon"
                />{" "}
                Back to all homes
              </button>
              <img
                src={
                  propertyData?.Image_Urls?.length > 0
                    ? propertyData?.Image_Urls
                    : "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                }
                alt={propertyData?.propertyId}
                className="w-[530px] h-[530px] rounded-[20px]"
              />
            </div>
          </div>
        
          <div className="w-full flex flex-col items-center text-center">
            <ProgressBar currentStep={7}  />
            <h1 className="font-circular text-[50px] font-[450] leading-[88.55px] text-center mb-[20px] text-customGray">
              Submit earnest money
            </h1>
            {/* <p className="font-circular text-[22px] font-[450] leading-[27.83px] text-center w-[572px] h-[84px] mb-[55px] text-customGray">
              Let's secure your home with {propertyData?.earnest_price} of
              earnest money. Along with the contract, this will help insure we
              get you into your new home.
            </p> */}
             <p className="font-circular text-[22px] font-[450] leading-[27.83px] text-center w-[572px] h-[84px] mb-[55px] text-customGray">
              Let's secure your home with $1000 of
              earnest money. Along with the contract, this will help insure we
              get you into your new home.
            </p>
            <div className="flex flex-col items-center space-y-6 w-full pl-5 pr-5">
              <div className="w-full text-center flex justify-center">

                {loading ? (
                  <button
                    type="button"
                    className={`start-btn flex justify-center font-circular font-[450] text-white text-[18px] bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] `}
                  >
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />{" "}
                    Processing...
                  </button>
                ) : accountLinkStatus ? (
                  <button
                    type="button"
                    onClick={() => submitEarnest()}
                    className={`start-btn font-circular font-[450] text-white text-[18px] bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] `}
                  >
                    Submit earnest Money
                  </button>
                ) : (
                  <button
                      type="button"
                      onClick={() => plaidOpen()}
                      disabled={!ready}
                      className={`next-first-btn text-[18px] font-circular text-white font-semibold ${plaidStatus ? 'bg-[#4b5563]': 'bg-[#111112]'} rounded-full hover:bg-[${builderConfig?.brandColor}] focus:outline-none  focus:ring-offset-2`}
                    >
                      Verify bank account
                    </button>
                )}
              </div>
            </div>
            {/* <div className="flex w-full justify-between mt-[115px]">
              <button
                type="button"
                className="p-[13px] bg-white text-gray-700 border border-[#E3E3E3] rounded-full flex items-center justify-center"
              >
                <img src="./assets/images/back-arrow-icon.svg" />
              </button>

              <button
                type="button"
                className="next-first-btn text-[18px] bg-[#F5F4F0] text-[#434D4F80] font-[450] rounded-full flex items-center justify-center"
              >
                Next
              </button>
            </div> */}
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Error Modal"
          className="bg-white rounded-lg py-12 px-12 max-w-4xl w-full text-center relative" // White background for content
          overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center" // Gray background for outer area
        >
          {/* Cross Icon for Closing Modal */}
          <button
            onClick={toggleModal}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
          >
            <FaTimes size={20} />
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TbFaceIdError fontSize={"100"} color="#d66c42" />
            <h2 className={`text-2xl font-bold text-[${builderConfig?.brandColor}] mb-4`}>Oops!</h2>
            <h2 className="text-xl text-gray-600 mb-1">
              you have insufficient balance for the earnest money
            </h2>
          </div>
        

          <button
            type="button"
            onClick={() => closeModal()}
            className={`mt-4 px-8 py-3 text-white font-semibold bg-[${builderConfig?.brandColor}] rounded-full hover:bg-[${builderConfig?.brandColor}] focus:outline-none focus:ring-2  focus:ring-offset-2`}
          >
            Try another account
          </button>
        </Modal>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {propertyData?.property_line?.length > 0
                ? propertyData?.property_line
                : "305 Bennett Way"}
            </p>
            <p className="font-circular text-customGray text-[17px]">
              {propertyData?.property_address?.length > 0
                ? propertyData?.property_address
                : "Willow Ridge, Georgia 30132"}
            </p>
            <a className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]">
              See details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarnestMoneyComponent;
