/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";

function GettingStarted() {
  const { isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [builderConfig, setBuilderConfig] = useState({});
  const navigate = useNavigate();
  const [builderInfo, setBuilderInfo] = useState({});
  const [propertyData, setPropertyData] = useState({
    builderID: "",
    propertyId: "",
    property_Url: "",
    Image_Urls: "",
    property_line: "",
    property_address: "",
    price: "",
  });
  

  const getBuilderInfo = async () => {
    let builderValue = localStorage.getItem("builderInfo");
    builderValue = JSON.parse(builderValue);
    setBuilderInfo(builderValue);
  }



  const getBuilderConfig = async () => {
    let builderConfigValue = localStorage.getItem("builderConfig");
    builderConfigValue = JSON.parse(builderConfigValue);
    setBuilderConfig(builderConfigValue);
  }
  const getPropertyData = async () => {
    let propertyValue = localStorage.getItem("propertyInfo");
    propertyValue = JSON.parse(propertyValue);
    setPropertyData(propertyValue);
  }

useEffect(() => {
  const processData = async () => {
    await getBuilderInfo();
          await getBuilderConfig();
          await getPropertyData();
          setIsLoading(false);
  };

  processData();
}, []);

  const login = () => {
    if (isAuthenticated) {
      navigate("/user-info");
    } 
  };
  return isLoading? ( <Loader /> ) :  (
    <div className="min-h-screen flex flex-col items-center">
      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row gap-[144px] items-center mt-10">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]"></div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <img
              src={builderConfig?.logo}
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">
              Powered by Homesy.
            </p>
          </div>
        </div>
      </div>

      <div className="w-4/5 mx-auto">
        <div className="flex flex-col md:flex-row items-center mb-[10px]">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {"Your Home"}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-[144px] items-center">
          <div className="w-[598px] mb-6 md:mb-0 md:pr-6">
            <div className="w-[598px]">
              <img
                src={
                  propertyData?.Image_Urls?.length > 0
                    ? propertyData?.Image_Urls
                    : "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"
                }
                alt={propertyData?.propertyId}
                className="w-[530px] h-[530px] rounded-[20px]"
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center text-center">
            <h1 className="font-circular text-[70px] font-[450] leading-[68.31px] text-center mb-[56px] text-customGray">
              Let's get your home.
            </h1>
         
              <button
                className={`bg-[${builderConfig?.brandColor}] text-white start-btn py-[] px-4 rounded-full font-circular font-light  text-[18px]`}
                onClick={() => login()}
              >
                Get started
              </button>
            
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="mt-[20px] ml-4">
            <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
              {propertyData?.property_line?.length > 0
                ? propertyData?.property_line
                : "305 Bennett Way"}
            </p>
            <p className="font-circular text-customGray text-[17px]">
              {propertyData?.property_address?.length > 0
                ? propertyData?.property_address
                : "Willow Ridge, Georgia 30132"}
            </p>
            <a
              className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]"
              // onClick={() => logout()}
            >
              See details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GettingStarted;
